<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','is_leader','is_attendance','user_status','department','is_sync','role_name']" ref="list"
			:submit_preprocessing="submit_preprocessing">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'"  type="primary"  @click="add()">添加员工</a-button>
				<!-- <a-button  type="primary" class="float_left" v-auth="'change'" style="margin-left:15px" @click="facevisible=true"
					>人脸录入权限</a-button> -->
				<a-button class="float_left" v-auth="'change'" style="margin-left:15px" @click="update_department()"
					type="">更新组织架构</a-button>
				
			</template>

			<template slot="right_btn">
				<export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.export/userExport">
				</export-table>
				<import-upload v-if="this.$store.state.page_auth.import" url="/manage/crm.export/userImport">
				</import-upload>
				<a :href="'/static/excel/人员档案导入模板.xlsx' | export_form" v-if="this.$store.state.page_auth.export"
					target="_blank">
					<a-button class="a_btn">模板下载</a-button>
				</a>
			</template>

			<template slot="is_leader" slot-scope="data">
				<span v-if="data.text==0">否</span>
				<span v-else-if="data.text==1">是</span>
			</template>
			<template slot="is_sync" slot-scope="data">
				<span v-if="data.text==-1">否</span>
				<span v-else-if="data.text==1">是</span>
			</template>
			

			<template slot="is_attendance" slot-scope="data">
				<span v-if="data.text==0">否</span>
				<span v-else-if="data.text==1">是</span>
			</template>

			<template slot="user_status" slot-scope="data">
				<span v-if="data.text==-1">离职</span>
				<span v-else-if="data.text==1">在职</span>
			</template>

			<template slot="role_name" slot-scope="data">
				<span v-for="it in data.record.role_name_new">
				{{it}} <br v-if='data.record.role_name_new.length>1' />
				</span>
			</template>
			
			<template slot="department" slot-scope="data">
				<span v-for="it in data.record.department_join.department_list">
				{{it}} <br v-if='data.record.department_join.department_list.length>1' />
				</span>
			</template>

			<template slot="operation" slot-scope="data">
				<a @click="edit(data.record,data.index)">编辑</a>
				<a-divider type="vertical" />
				<a @click="rest_password(data.record)">初始化密码</a> 
			</template>
		</TableList>
		<EditPop :form_data="form_data" :visible.sync="visible"></EditPop>
		<EditPop :form_data="face_form_data" :visible.sync="facevisible"></EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import {
		user_index,
		updateDepartment,userPasswordInit
	} from "@/api/user";
	import TableList from "@/components/TableList";
	import deactivated from '@/methods/deactivated'
	const columns = [{
			title: "ID",
			dataIndex: "id",
			// fixed: 'left',
			// width: 100,
			scopedSlots: {
				customRender: "id"
			}
		},
		{
			title: "姓名",
			dataIndex: "username",
			scopedSlots: {
				customRender: "username"
			}
		},
		{
			title: "部门",
			dataIndex: "department",
			scopedSlots: {
				customRender: "department"
			}
		},
		{
			title: "岗位",
			dataIndex: "role_name",
			scopedSlots: {
				customRender: "role_name"
			}
		},
		{
			title: "账号",
			dataIndex: "phone",
			scopedSlots: {
				customRender: "phone"
			}
		},
		{
			title: "部门领导",
			dataIndex: "is_leader",
			scopedSlots: {
				customRender: "is_leader"
			}
		},
		{
			title: "考勤打卡",
			dataIndex: "is_attendance",
			scopedSlots: {
				customRender: "is_attendance"
			}
		},
		{
			title: "在职状态",
			dataIndex: "user_status",
			scopedSlots: {
				customRender: "user_status"
			}
		},
		{
			title: "是否同步人事系统",
			dataIndex: "is_sync",
			scopedSlots: {
				customRender: "is_sync"
			}
		},
		
		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	const data = [];
	for (let i = 0; i < 100; i++) {
		data.push({
			key: i.toString(),
			id: `${i}`,
			username: "李雪",
			department_name: "江西大区/南昌/南昌市区办",
			role_name: "大区经理",
			phone: "15007003464",
			is_leader: 1,
			is_attendance: "否",
			user_status: 0
		});
	}

	export default {
		name: "IndexUser",
		components: {
			EditPop,
			Form,
			TableList
		},
		data() {
			return {
				facevisible:false,
				get_table_list: user_index,
				expand: false,
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				loading: false,
				list: [],
				submit_preprocessing: {
					array_to_string: ['department_id','role_id']
				},
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				data,
				visible: false,
				form_data_seo: {
					list: [{
							type: "tree-select",
							name: "department_id",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "tree-select",
							name: "role_id",
							title: "岗位职务",
							mode: "default",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "text",
							name: "keyword",
							title: "员工",
							placeholder: '姓名/ID/手机号',
							options: {

							}
						},
						// {
						//     type: "select",
						//     name: "is_attendance",
						//     title: "考勤打卡",
						//     mode: "default",
						//     options: {},
						//     list: [
						//         {
						//             key: "1",
						//             value: "是"
						//         },
						//         {
						//             key: "0",
						//             value: "否"
						//         }
						//     ]
						// },
						{
							type: "select",
							name: "status",
							title: "在职状态",
							mode: "default",
							options: {},
							list: this.$config.user_status_list
						}
					],
					...this.$config.form_data_seo
				},
				form_data: {},
				face_form_data:{
					list: [{
							type: "radio",
							name: "status",
							title: "收集范围",
							options: {
								rules: [{
									required: true,
									message: "请选择状态"
								}],
								initialValue: 1
							},
							list: [{
									key: 1,
									value: "部门"
								},
								{
									key: -1,
									value: "岗位"
								}
							]
						},
						
						{
								type: "tree-select",
								name: "department_id",
								title: "部门",
								options: {},
								treeData: [],
								multiple: true
							},
							{
								type: "tree-select",
								name: "role_id",
								title: "岗位职务",
								mode: "default",
								options: {},
								treeData: [],
								multiple: true
							},
							{
									type: "radio",
									name: "status",
									title: "强制收集",
									options: {
										rules: [{
											required: true,
											message: "请选择状态"
										}],
										initialValue: 1
									},
									list: [{
											key: 1,
											value: "是"
										},
										{
											key: -1,
											value: "否"
										}
									]
								},
						]
				},
			};
		},

		deactivated() {
			// this.$keep_route.remove(this.$options.name);
		},
		async created() {
			// 为页面添加缓存
			this.get_list();
			this.$method.get_department().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "department_id") {
						item.treeData = res;
					}
				});
				this.face_form_data.list.forEach(item => {
					if (item.name == "department_id") {
						item.treeData = res;
					}
				});
			});
			// this.$method.get_role().then(res => {
			// 	this.form_data_seo.list.forEach(item => {
			// 		if (item.name == "role_id") {
			// 			item.list = [...this.$config.status_all, ...res];
			// 		}
			// 	});
			// });
			this.$method.get_role_tree().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "role_id") {
						item.treeData = res;
					}
				});
				this.face_form_data.list.forEach(item => {
					if (item.name == "role_id") {
						item.treeData = res;
					}
				});
				
			});
		},
		methods: {
			update_department() {
				updateDepartment({
					info: true
				}).then(() => {

				});
			},
			async handleTableChange(pagination) {
				this.page = pagination.current;
				await this.get_list();
			},
			async get_list(data = {}) {
				try {
					await user_index({
						_this: this,
						data
					}).then(res => {
						let list = res.res.data.list.data;
						this.list = list.map(item => {
							item.department_name = item.department_join.department_join;
							return item;
						});
					});
				} catch (e) {
				}
			},
			rest_password(data){
				// 初始化密码
				userPasswordInit({data:{
					id:data.id
				},info:true}).then(res=>{
					this.get_list();
				})
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {});
			},

			handleReset() {
				this.form.resetFields();
				this.list = [];
				this.get_list();
			},
			handleSubmit(e) {
				let data = e.values;
				if (data.department_id && data.department_id.length) {

					data.department_id = data.department_id.join(',');
				}

				this.list = [];
				this.get_list(data);
			},
			toggle() {
				this.expand = !this.expand;
			},

			handleChange(value, key, column) {
				const newData = [...this.data];
				const target = newData.filter(item => key === item.key)[0];
				if (target) {
					target[column] = value;
					this.data = newData;
				}
			},
			add() {
				this.$keep_route.add(this.$options.name);
				//   this.visible = true;
				this.$router.push("/user/index_edit");
			},
			edit(key) {
				this.$keep_route.add(this.$options.name);
				this.$router.push("/user/index_edit?id=" + key.id);
			},
			save(key) {
				const newData = [...this.data];
				const newCacheData = [...this.cacheData];
				const target = newData.filter(item => key === item.key)[0];
				const targetCache = newCacheData.filter(item => key === item.key)[0];
				if (target && targetCache) {
					delete target.editable;
					this.data = newData;
					Object.assign(targetCache, target);
					this.cacheData = newCacheData;
				}
			},
			cancel(key) {
				const newData = [...this.data];
				const target = newData.filter(item => key === item.key)[0];
				if (target) {
					Object.assign(
						target,
						this.cacheData.filter(item => key === item.key)[0]
					);
					delete target.editable;
					this.data = newData;
				}
			}
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
</style>
